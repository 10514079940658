import { Component, Vue } from 'vue-property-decorator';
import layout from '@/layouts/basic/basic.vue';

import Title1 from '@/components/title1/title1.vue';
import ListAllPosts from '@/modules/posts/components/listAllPosts/listAllPosts.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    layout,
    Title1,
    ListAllPosts,
    ShowPath
  }
})
export default class Boletins extends Vue {

}
  